export const LOGIN = '/login';
export const JWT_VERIFY = '/auth/verifyToken';
export const RESETPASSWORD = profile => {
  return `/${profile.companyKey}/${profile.userName}/resetpassword`;
};
export const CHANGEPASSWORD = profile => {
  return `/user/${profile.userName}/changepassword`;
};

export const Profile = {
  get: '/Profiles',
  updateCache: ({ companyKey, profileId }) =>
    `cache/company-${companyKey}/profile-${profileId}`
};
export const CompanyAPI = {
  get: '/company',
  put: '/company',
  getAll: '/companies',
  getAssignedCompanies: '/companies/assigned',
  export: 'companies/export',
  hasAppointmentStatusEnable: '/companies/isAppointmentScheduler',
  getCompanyConfig: '/companies/configuration',
  updateVoice: companyId => `/company/voice/${companyId}`,
  getVoiceReps: companyId => `/company/voiceRepresentatives/${companyId}`,
  saveVoiceReps: companyId => `/company/voiceRepresentatives/${companyId}`,
  deleteVoiceRep: (companyId, id) =>
    `/company/${companyId}/voiceRepresentatives/${id}`,
  getSalesReps: companyId => `/company/salesRepresentatives/${companyId}`,
  saveSalesReps: companyId => `company/salesRepresentatives/${companyId}`,
  deleteSalesRep: (id, companyId) =>
    `/company/${companyId}/salesRepresentatives/${id}`,
  resendEmail: id => `company/salesRepresentatives/resendEmail/${id}`,
  schedulingPages: id => `company/schedulingPages/${id}`,
  saveSchedulingPage: companyId => `company/schedulingPage/${companyId}`,
  deleteSchedulingPage: id => `company/schedulingPages/${id}`,
  saveThirdPartySchedule: companyId =>
    `company/thirdPartyScheduling/${companyId}`,
  associateSalesReps: pageId =>
    `company/schedulingPage/salesRepresentative/${pageId}`,
  getAssociatedPages: (companyId, salesReptId) =>
    `company/${companyId}/schedulingPages/${salesReptId}`,
  isDirtySchedulingPage: pageId => `company/schedulingPage/isDirty/${pageId}`
};

export const ChatAPI = {
  get: '/chats',
  put: '/company',
  getAll: '/chats',
  getGroups: '/groups/assigned',
  messagingDashboardStatus: status => `users/active/${status}`,
  endChat: chatId => `chatrooms/${chatId}/close`,
  rejectChat: chatId => `chats/${chatId}/text/reject`,
  getChatRoomData: chatId => `chatrooms/${chatId}`,
  getSuggestions: (companyId, threeLetterISOLanguageName) =>
    `chatroom/suggestions?companyId=${companyId}&threeLetterISOLanguageName=${threeLetterISOLanguageName}`,
  getUnsentLeads: `chatrooms/?filter=myleads`,
  getChatsHistory: `chatrooms/`,
  updateUnsentLead: ({ chatId, isLead }) =>
    `chatrooms/${chatId}/flaglead?isLead=${isLead}`,
  export: 'chats',
  setMessagesAsRead: chatId => `/updatechatmessagesasread/${chatId}`,
  setMessageLeadAsRead: leadId => `/markmessageleadasread/${leadId}`,
  getOperators: ({ chatId, currentUserId }) =>
    `/getOperatorsForTransfer/?chatId=${chatId}&currentUserId=${currentUserId}`,
  transferChatToOperator: ({ chatId, userId, transferType, language }) =>
    `/transferChat/?chatId=${chatId}&transferType=${transferType}&language=${language}&userId=${userId}`,
  getAgents: ({ isAgent, date }) =>
    `/agents/${isAgent}${date ? `?date=${date}` : ''}`,
  exportOperators: date => `/agents/false${date ? `?date=${date}` : ''}`,
  agentActivityDetails: id => `/agentactivitydetails/${id}`,
  getLastConvo: id => `chats/transcript/${id}?getLastConvo=true`,
  getVisitorHistory: 'chatrooms/getVisitorHistory/',
  getTranscript: id => `chats/transcript/${id}`
};

export const StatementAPI = {
  getFolders: (companyId, threeLetterISOLanguageName) =>
    `/scripts/multiLanguage/?companyId=${companyId}&threeLetterISOLanguageName=${threeLetterISOLanguageName}`,
  addFolder: '/messaging/folder/add',
  addStatement: '/messaging/suggestions/add',
  removeStatement: '/messaging/suggestions/delete'
};

export const MDSignUpAPI = 'messaging/signuprequest';

export const ChatTranferAPI = {
  chatTransferUpdate: data =>
    `chatTransfer/${data.chatId}/response/?userId=${data.userId}&response=${data.response}&message=${data.message}`
};

export const UserAPI = {
  get: '/user',
  put: '/user',
  getAll: '/users',
  getAgents: '/users/agentlist',
  getUserHasMultipleCompanies: 'users/multiplecompany',
  uploadImage: userId => `/user/${userId}/upload`,
  invite: companyId => `users/companies/${companyId}/invite`,
  remove: userId => `users/delete/${userId}`,
  preferenceSettings: userId => `/user/${userId}/preferenceSettings`
};

export const LeadAPI = {
  get: '/leads',
  put: '/leads',
  getAll: '/leads',
  post: '/leads',
  export: '/leads',
  getLeadCategories: companyId => `company/${companyId}/leadcategories`,
  getRegions: companyId => `company/${companyId}/regions`,
  forwardlead: ({ leadId, emails }) =>
    `forwardlead/?leadId=${leadId}&to=${emails}`,
  changeRequestLeadType: ({ leadId, leadType }) =>
    `leads/change/${leadId}/${leadType}/?action=request`
};

export const CallConnectAPI = {
  post: data => `/calls/?to=${data.to}&from=${data.from}&chatId=${data.chatId}`
};

export const AnalyticAPI = {
  getActivationStats: '/activationstats',
  getLeadgeneratorStats: '/leadgeneratorstats',
  getTopBottomBillersStats: '/leadgeneratorstats',
  getQuickStats: '/quick-stats',
  getFilters: '/getFilters',
  getTopRevenueStats: '/leadgeneratorstats',
  getTopInactiveBillerStats: '/leadgeneratorstats',
  getBudgetPacingStats: '/leadgeneratorstats',
  getAgentImagesCTRStats: '/leadgeneratorstats',
  getAccountsStats: '/leadgeneratorstats',
  getChartsAndGraphs: '/chartsNgraphs'
  // put: '/leads',
  // getAll: '/leads'
};

export const general = {
  getMobileSuggestions: '/general/mobile/suggestions',
  getBusinessCategories: 'general/businesscategories',
  getBusinessSubCategories: 'general/businessSubCategory',
  getServerInfo: 'general/AppInfo'
};

export const ReportAPI = {
  get: '/report',
  put: '/report',
  getAll: '/report',
  getList: '/report/reporttypelist',
  post: '/userreports/',
  updateQueuedReport: '/report/updatequeuedreports'
};
export const HostAPI = {
  getOwnedHosts: '/hosts/ownedhosts',
  getHost: id => `host/${id}`,
  getMeta: data => {
    return `/host/meta?meta.hostDomain=${data?.hostDomain}&meta.hostKey=${data?.hostKey}`;
  }
};
export const ProvisionAPI = {
  get: id => `/provision/${id}`,
  getAll: '/provision',
  create: '/provision',
  update: '/provision',
  attachements: '/provision/attachment',
  export: 'provisioningrequests/',
  getFeaturesByHostId: hostId => `/provision/offered-host-features/${hostId}`,
  getFeaturesByProvisioningId: provisioningId =>
    `/provision/offered-provision-features/${provisioningId}`,
  updateVoice: provisionId => `/provision/voice/${provisionId}`,
  getVoiceReps: provisionId => `/provision/voiceRepresentatives/${provisionId}`,
  saveVoiceReps: provisionId =>
    `/provision/voiceRepresentatives/${provisionId}`,
  deleteVoiceRep: (provisionId, id) =>
    `/provision/${provisionId}/voiceRepresentatives/${id}`,
  getAppointDetails: (provisionId, type) =>
    `/provision/appointmentdetails/${type}/${provisionId}`,
  saveAppointDetails: (provisionId, type) =>
    `/provision/appointmentdetails/${type}/${provisionId}`,
  deleteAppointDetails: id => `/provision/appointmentdetails/${id}`,
  getSalesRepById: id => `/provision/salesRepresentative/${id}`,
  getSalesReps: provisionId => `/provision/salesRepresentatives/${provisionId}`,
  saveSalesReps: provisionId =>
    `/provisioningrequests/${provisionId}/salesRepresentative`,
  deleteSalesRep: id => `/provision/salesRepresentatives/${id}`,
  resendEmail: id =>
    `/provisioningrequests/resendEmail/salesRepresentative/${id}`
};

export const MyReportAPI = {
  getAll: '/report',
  export: 'userreports'
};

export const QueuedReportAPI = {
  getAll: '/report/queued',
  downloadQueuedReport: (id, format) =>
    `queueduserreports/${id}?format=${format}`,
  export: 'queueduserreports'
};

export const WidgetEditor = {
  sendEmail: `/settings/widgetcode/email`,
  getProfile: profileId => `/profiles/${profileId}`,
  getWidget: path => `themes/skinnable/${path}`
};
export const GeneraticeAIAPI = {
  createChatBot: (name, companyId) =>
    `/chatbot/create?chatbotName=${name}&companyId=${companyId}`,
  getAll: companyId => `/chatbot?companyId=${companyId}`,
  uploadChatbot: (botId, companyId) =>
    `/chatbot/upload?botId=${botId}&companyId=${companyId}`,
  getIndexes: (botId, companyId) =>
    `/chatbot/index-names?botId=${botId}&companyId=${companyId}`,
  deleteIndex: (indexName, fileName = '', url = '', companyId) => {
    let apiUrl = `/chatbot/delete-index?indexName=${indexName}&companyId=${companyId}`;
    if (url) {
      apiUrl += `&url=${encodeURIComponent(url)}`;
    }
    if (fileName) {
      apiUrl += `&fileName=${encodeURIComponent(fileName)}`;
    }
    return apiUrl;
  },
  addURIToChatbot: (url, chatbotId, companyId) =>
    `/chatbot/upload-url?botId=${chatbotId}&url=${encodeURIComponent(
      url
    )}&companyId=${companyId}`,
  addQuestionToChatbot: (questionAnswer, chatbotId, companyId) =>
    `/chatbot/add-question?chatbotId=${chatbotId}&questionAnswer=${encodeURIComponent(
      questionAnswer
    )}&companyId=${companyId}`,
  deleteChatbotQuestion: (chatbotId, questionId, companyId) =>
    `/chatbot/delete-question?chatbotId=${chatbotId}&questionId=${questionId}&companyId=${companyId}`,
  updateQuestionToChatbotAPI: (
    questionAnswer,
    chatbotId,
    companyId,
    questionId
  ) =>
    `/chatbot/update-question?chatbotId=${chatbotId}&questionAnswer=${encodeURIComponent(
      questionAnswer
    )}&companyId=${companyId}&questionId=${questionId}`,
  getChatbotConversations: (botId, companyId, pageNumber, pageSize) =>
    `/chatbot/chats?chatbotId=${botId}&companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  deleteChatbotConversation: (chatId, companyId) =>
    `/chatbot/delete-chat?chatId=${chatId}&companyId=${companyId}`,
  saveConversationRating: (chatId, companyId, rating, improvedAnswer) =>
    `/chatbot/save-rating?chatId=${chatId}&companyId=${companyId}&rating=${rating}&improvedAnswer=${improvedAnswer}`,
  getQuestions: (botId, companyId, pageNumber, pageSize) =>
    `/chatbot/questions?chatbotId=${botId}&companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
};
