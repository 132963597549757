import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getInitialNodes } from '../scriptbot_flow/scriptbotflow-slice';
import {
  addNode,
  deleteNode,
  deleteNodes,
  initializeFlow
} from '../flow/flow-slice';
import { BlazeoApi } from '../integration';

const blazeoApi = new BlazeoApi();

export const loadAgentScriptAnswers = createAsyncThunk(
  'agentScritpAnswers/getAll',
  async args => {
    const { companyId, nodes } = args;

    if (isNaN(+companyId)) {
      throw 'Error';
    }

    const allQuestions = [];
    const nodeIds = nodes.map(node => node.id);

    const agentScript = (await blazeoApi.getAgentScript(companyId)).data.data;

    agentScript.questions.forEach(question => {
      question.answers.forEach(answer => {
        if (nodeIds.includes(answer.id.toString())) {
          answer.inFlow = true;
        } else {
          answer.inFlow = false;
        }
        allQuestions.push(answer);
      });
    });

    return {
      allQuestions: allQuestions
    };
  }
);

const initialState = {
  all: []
};

export const agentScriptAnswersSlice = createSlice({
  name: 'agentScriptAnswers',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInitialNodes.fulfilled, (state, action) => {
        state.all = action.payload.allQuestions;
      })
      .addCase(loadAgentScriptAnswers.fulfilled, (state, action) => {
        state.all = action.payload.allQuestions;
      })
      .addCase(addNode, (state, action) => {
        const allQuestions = [];
        state.all.forEach(question => {
          if (question.id == action.payload.data.question.id) {
            question.inFlow = true;
          }
          allQuestions.push(question);
        });
        state.all = allQuestions;
      })
      .addCase(deleteNode, (state, action) => {
        const allQuestions = [];
        state.all.forEach(question => {
          if (question.id == action.payload.data.question.id) {
            question.inFlow = false;
          }
          allQuestions.push(question);
        });
        state.all = allQuestions;
      })
      .addCase(deleteNodes, (state, action) => {
        const allQuestions = [];
        state.all.forEach(question => {
          action.payload.forEach(deletingNodes => {
            if (question.id == deletingNodes.data.question.id) {
              question.inFlow = false;
            }
          });
          allQuestions.push(question);
        });
        state.all = allQuestions;
      })
      .addCase(initializeFlow, (state, action) => {
        const allQuestions = [];
        state.all.forEach(question => {
          question.inFlow = false;
          allQuestions.push(question);
        });
        state.all = allQuestions;
      });
  }
});

export const { updateQuestionTree } = agentScriptAnswersSlice.actions;
export default agentScriptAnswersSlice.reducer;
