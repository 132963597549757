import { initialEdges } from '../edges';
import { initialNodes } from '../nodes';
import { createSlice } from '@reduxjs/toolkit';
import { applyEdgeChanges, applyNodeChanges, addEdge } from '@xyflow/react';
import { getLayoutedElements } from '../nodes/layoutModifier';
import { updateOptionText } from '../menu/menu-slice';
import { getInitialNodes } from '../scriptbot_flow/scriptbotflow-slice';

const nodes = initialNodes;
const edges = initialEdges;

const flowState = {
  nodes: nodes,
  edges: edges,
  reload: false
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState: flowState,
  reducers: {
    initializeFlow: state => {
      state.nodes = initialNodes;
      state.edges = initialEdges;
      state.reload = false;
    },
    addNode: (state, action) => {
      if (action.payload.data.question.questionType === null)
        action.payload.data.question.questionType = 0;

      state.nodes.push(action.payload);
    },
    addNewEdge: (state, action) => {
      state.edges.push(action.payload);
    },
    addNewEdges: (state, action) => {
      action.payload.forEach(edge => {
        state.edges.push(edge);
      });
    },
    onNodesChangeRedux: (state, action) => {
      state.nodes = applyNodeChanges(action.payload, state.nodes);
    },
    onEdgesChangeRedux: (state, action) => {
      state.edges = applyEdgeChanges(action.payload, state.edges);
    },
    onConnectRedux: (state, action) => {
      state.edges = addEdge(action.payload, state.edges);
    },
    setFlowState: (state, action) => {
      state.nodes = action.payload.nodes;
      state.edges = action.payload.edges;

      state.reload = true;
    },
    setNodes: (state, action) => {
      state.nodes = action.payload;
    },
    setEdges: (state, action) => {
      state.edges = action.payload;
    },
    updateNode: (state, action) => {
      state.nodes = state.nodes.map(node => {
        if (node.id === action.payload.id) return action.payload;
        else return node;
      });
    },
    deleteNode: (state, action) => {
      const newNodes = [];

      state.nodes.forEach(node => {
        if (node.id !== action.payload.id) newNodes.push(node);
      });
      state.nodes = newNodes;

      const newEdges = [];
      state.edges.forEach(edge => {
        if (
          edge.source !== action.payload.id &&
          edge.target !== action.payload.id
        )
          newEdges.push(edge);
      });
      state.edges = newEdges;
    },
    deleteNodes: (state, action) => {
      const newNodes = [];

      action.payload.forEach(deletingNode => {
        state.nodes.forEach(node => {
          if (node.id !== deletingNode.id) newNodes.push(node);
        });
        state.nodes = newNodes;

        const newEdges = [];
        state.edges.forEach(edge => {
          if (
            edge.source !== deletingNode.id &&
            edge.target !== deletingNode.id
          )
            newEdges.push(edge);
        });
        state.edges = newEdges;
      });
    },
    deleteEdge: (state, action) => {
      const newEdges = [];

      state.edges.forEach(edge => {
        if (
          edge.source !== action.payload.id &&
          edge.target !== action.payload.target
        )
          newEdges.push(edge);
      });
      state.edges = newEdges;
    },
    onReorganize: (state, action) => {
      const flowState = getLayoutedElements(
        state.nodes,
        state.edges,
        action.payload
      );
      state.nodes = flowState.nodes;
      state.edges = flowState.edges;

      state.reload = true;
    },
    resetReload: state => {
      state.reload = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getInitialNodes.fulfilled, (state, action) => {
        const initialEdge = {
          id: `start->${action.payload.firstQuestionId}`,
          type: 'question-edge',
          source: 'start',
          target: action.payload.firstQuestionId.toString()
        };

        const nodes = action.payload.nodes.concat(initialNodes);
        const edges = action.payload.edges.concat(initialEdge);

        const flowState = getLayoutedElements(nodes, edges, 'TB');

        state.nodes = flowState.nodes;
        state.edges = flowState.edges;

        state.reload = true;
      })
      .addCase(updateOptionText, (state, action) => {
        const newEdges = [];
        state.edges.forEach(edge => {
          if (
            edge.source === action.payload.source &&
            edge.target === action.payload.target
          ) {
            edge.label = action.payload.text;
          }
          newEdges.push(edge);
        });
        state.edges = newEdges;
      });
  }
});

export const {
  initializeFlow,
  addNode,
  addNewEdge,
  addNewEdges,
  onNodesChangeRedux,
  onEdgesChangeRedux,
  onConnectRedux,
  onReorganize,
  deleteNode,
  deleteNodes,
  deleteEdge,
  updateNode,
  setFlowState,
  resetReload
} = flowSlice.actions;
export default flowSlice.reducer;
