import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BlazeoDataPromise } from '../integration';

const position = { x: 0, y: 0 };

export const getInitialNodes = createAsyncThunk(
  'questions/fetchAll',
  async companyId => {
    if (isNaN(+companyId)) {
      throw 'Error';
    }
    const blazeoData = await BlazeoDataPromise(parseInt(companyId));

    const dataNodes = [];
    const dataEdges = [];

    const allQuestions = [];
    const usedQuestions = [];
    const unusedQuestions = [];

    const nextQuestionId = [];

    blazeoData.agentScript.questions.forEach(question => {
      question.answers.forEach(answer => {
        const answerId = answer.id.toString();

        allQuestions.push(answer);

        switch (answer.questionType) {
          case 1: // Multiple Choice
          case 2: // True or False
          case 7: // All that Apply
            if (
              answer.agentScriptOptions &&
              answer.agentScriptOptions.length > 0
            ) {
              const nextQuestionMapping = {};

              const node = {
                id: answerId,
                type: 'question-type',
                position: position,
                data: {
                  question: answer,
                  firstQuestion: false
                }
              };

              if (dataNodes.findIndex(n => n.id === node.id) === -1) {
                dataNodes.push(node);
                usedQuestions.push(answer);
              } else {
                unusedQuestions.push(answer);
              }

              answer.agentScriptOptions.forEach(option => {
                if (option.nextQuestionId > 0) {
                  if (nextQuestionMapping[option.nextQuestionId]) {
                    nextQuestionMapping[option.nextQuestionId] +=
                      '|' + option.text;
                  } else {
                    nextQuestionMapping[option.nextQuestionId] = option.text;
                  }
                }
              });

              Object.entries(nextQuestionMapping).forEach(
                ([nextQuestionId, options]) => {
                  const edge = {
                    id: `${answerId}->${nextQuestionId}`,
                    type: 'question-edge',
                    source: answerId,
                    target: nextQuestionId,
                    label: options
                  };
                  if (dataEdges.findIndex(e => e.id === edge.id) === -1)
                    dataEdges.push(edge);
                }
              );
            }

            break;
          default:
            if (answer.nextQuestionId || nextQuestionId.includes(answer.id)) {
              const nextAnswerId = answer.nextQuestionId?.toString();

              const node = {
                id: answerId,
                type: 'question-type',
                position: position,
                data: {
                  question: answer,
                  firstQuestion: false
                }
              };
              if (dataNodes.findIndex(n => n.id === node.id) === -1) {
                dataNodes.push(node);
                usedQuestions.push(answer);
              } else {
                unusedQuestions.push(answer);
              }

              nextQuestionId.push(answer?.nextQuestionId);

              if (answer.nextQuestionId) {
                const edge = {
                  id: `${answerId}->${nextAnswerId}`,
                  type: 'question-edge',
                  source: answerId,
                  target: nextAnswerId
                };
                if (dataEdges.findIndex(e => e.id === edge.id) === -1)
                  dataEdges.push(edge);
              }
            } else {
              unusedQuestions.push(answer);
            }
            break;
        }
      });
    });

    return {
      nodes: dataNodes,
      edges: dataEdges,
      firstQuestionId: blazeoData.chatGreetingAnswerId,
      allQuestions: allQuestions,
      usedQuestions: usedQuestions,
      unusedQuestions: unusedQuestions
    };
  }
);

const initialState = {
  visible: false,
  scriptBotFlow: {
    id: 0,
    companyId: '',
    chatBotId: '',
    chatBotName: '',
    isNew: false
  }
};

export const scriptBotFlowSlice = createSlice({
  name: 'scriptBotFlow',
  initialState: initialState,
  reducers: {
    setScriptBotFlow: (state, action) => {
      state.scriptBotFlow = action.payload;
    },
    show: (state, action) => {
      state.visible = true;
      state.scriptBotFlow.companyId = action.payload.companyId;
      state.scriptBotFlow.chatBotId = action.payload.chatbot_id;
      state.scriptBotFlow.chatBotName = action.payload.chatbot_name;
      state.scriptBotFlow.isNew = action.payload.isNew;
    },
    hide: state => {
      state.visible = false;
    }
  }
});

export const { show, hide, setScriptBotFlow } = scriptBotFlowSlice.actions;
export default scriptBotFlowSlice.reducer;
